<template>
  <div class="container-fluid py-3">
    <div class="row text-center">
      <div class="col-12 mb-5">
        <h5>{{ $t('matchesBet') }}</h5>
        <hr>
        <h5>{{ $t('bet') }} {{ match.bet }}</h5>
        <h3>{{ $t('gain') }} {{ match.gain }}</h3>
        <h5 v-if="match.date">{{ match.date }}<i class="fa fa-calendar mx-3"></i>{{ match.time }}</h5>
      </div>
      <div class="col-6">
        <b-button variant="primary" v-if="!isAuthenticated" :to="{ name: 'Login', params: { lang: lang } }">{{ $t('login') }}</b-button>
        <b-button v-if="isAuthenticated && !match.user_a_id" variant="primary" :to="{ name: 'VsEntry', params: { lang: lang, console: console, matchId: match.id, position: 'local' } }">{{ $t('entry') }}</b-button>
        <div class="row justify-content-center">
          <div class="col-8 col-sm-6 col-md-4">
            <img :src="match.user_a_avatar" :alt="match.user_a_nick" class="img-fluid">
          </div>
        </div>
        <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: match.user_a_slug } }">{{ match.user_a_nick }}</router-link>
        <hr>
        <div class="text-left">
          <p>{{ $t('comments') }}</p>
          <p>{{ match.comment_a }}</p>
          <img v-if="match.user_a_image" :src="match.user_a_image" alt="Imagen del local" class="img-fluid img-thumbnail mt-5">
        </div>
      </div>
      <div class="col-6">
         <b-button variant="primary" v-if="!isAuthenticated" :to="{ name: 'Login', params: { lang: lang } }">{{ $t('login') }}</b-button>
        <b-button v-if="isAuthenticated && !match.user_b_id" variant="primary" :to="{ name: 'VsEntry', params: { lang: lang, console: console, matchId: match.id, position: 'visitor' } }">{{ $t('entry') }}</b-button>
        <div class="row justify-content-center">
          <div class="col-8 col-sm-6 col-md-4">
            <img :src="match.user_b_avatar" :alt="match.user_b_nick" class="img-fluid">
          </div>
        </div>
        <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: match.user_b_slug } }">{{ match.user_b_nick }}</router-link>
        <hr>
        <div class="text-left">
          <p>{{ $t('comments') }}</p>
          <p>{{ match.comment_b }}</p>
          <img v-if="match.user_b_image" :src="match.user_b_image" alt="Imagen del visitante" class="img-fluid img-thumbnail mt-5">
        </div>
      </div>
      <div class="col-12">
        <a :href="match.url" target="_BLANK" class="btn btn-primary btn-lg"><i class="fa fa-facebook px-3"></i></a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      match: {}
    }
  },
  computed: {
    ...mapGetters([
      'console',
      'lang',
      'user',
      'isAuthenticated'
    ])
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      const matchId = this.$route.params.matchId
      const path = `${this.lang}/console/${this.console}/shop/bets/vs/${matchId}/details`
      this.$axios.get(path).then(response => {
        this.match = response.data.data
      })
    }
  }
}
</script>
